<template>
  <v-row
    class="px-10"
    dense
  >
    <v-col
      cols="12"
      md="12"
    >
      <v-card
        v-for="(address, idx) in client.client_address"
        :key="idx"
        class="grey lighten-4 pa-5 mt-3"
      >
        <v-row dense>
          <v-col
            cols="12"
            md="2"
          >
            <v-switch
              v-model="address.isDelivery"
              @change="clearDeliveryData(address)"
              label="Endereço de Entrega"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row
          dense
          v-if="address.isDelivery"
        >
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="address.name"
              label="Razão Social / Nome"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="address.phone"
              label="Telefone"
              placeholder="(xx) xxxxx-xxxx"
              v-mask="['(##) ####-####', '(##) #####-####']"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="address.cpf_cnpj"
              label="CPF / CNPJ"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="address.ie_rg"
              label="Inscrição Estadual / RG"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
        </v-row>
        <v-divider
          class="my-4"
          v-if="address.isDelivery"
        />
        <v-row dense>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="address.cep"
              @blur="getAddress(address)"
              label="CEP"
              placeholder="xxxxx-xxx"
              v-mask="'#####-###'"
              append-icon="fas fa-search-location"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              v-model="address.address"
              label="Endereço"
              placeholder="Digite o nome da rua"
              :loading="isLoading"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              label="N°"
              v-model="address.number"
              v-mask="'########'"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="10"
          >
            <v-text-field
              label="Complemento"
              v-model="address.complement"
              placeholder="Complemento"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              label="Bairro"
              v-model="address.district"
              placeholder="Digite o nome do bairro"
              :loading="isLoading"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-text-field
              label="Cidade"
              v-model="address.city"
              placeholder="Digite o nome da cidade"
              :loading="isLoading"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              label="UF"
              v-model="address.uf"
              :loading="isLoading"
              outlined
              rounded
              dense
              hide-details
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              @click="client.client_address.splice(idx, 1)"
              rounded
              outlined
              color="primary"
              >Remover Endereço</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-btn
        @click="addAddress"
        class="primary"
        icon
        dark
      >
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    clearDeliveryData(address) {
      if (address.isDelivery) {
        delete address.name
        delete address.phone
        delete address.cpf_cnpj
        delete address.ie_rg
      }
    },
    addAddress() {
      this.client.client_address.push({})
    },
    removeAddress(address) {
      let idx = this.client.client_address.indexOf(address)
      this.client.client_address.splice(idx, 1)
    },
    getAddress(address) {
      this.isLoading = true

      this.$api
        .get(`https://cep.awesomeapi.com.br/json/${address.cep}`)
        .then((res) => {
          address.city = res.data.city
          address.district = res.data.district
          address.uf = res.data.state
          address.address = res.data.address
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
